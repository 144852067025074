
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  name: "ServicesNavigation",
  components: {}
})
export default class ServicesNavigation extends Vue {
  public constructor() {
    super();
  }

  public aileronRedirect() {
    console.log("Clicked aileronRedirect");
  }

  public get contentPortalUrl(): string {
    return process.env.VUE_APP_CONTENT_PORTAL_URL;
  }

  public mounted(): void {}
}
