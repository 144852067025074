import Vue from "vue";
import Vuetify from "vuetify/lib";
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

const opts: any = {
  options: {
    customProperties: true
  },
  icons: {
    iconfont: "fa"
  },
  theme: {
    dark: false,
    default: 'light',
    themes: {
      light: {
        primary: "#152939",
        secondary: "#55677D",
        menu: "#31465F",
        secondarymenu: "#828282",
        action: "#007CC7",
        action2: "#00a7e1",
        secondaryaction: "#4DA8DA",
        accent: "#00a4f7",
        error: "#b71c1c",
        background: "#F3F2F2",
      },
      dark: {
        background: "#F3F2F2"
      }
    }
  }
};

export default new Vuetify(opts);
