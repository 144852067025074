import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { RegistrationRequest } from "@aileron/aileron-data-types/types/API/Identity/RegistrationRequest";
import { RegistrationResponse } from "@aileron/aileron-data-types/types/API/Identity/RegistrationResponse";
import { UserAuthenticationResponse } from "@aileron/aileron-data-types/types/API/Identity/UserAuthenticationResponse";
import { UserAuthenticationRequest } from "@aileron/aileron-data-types/types/API/Identity/UserAuthenticationRequest";

import { BaseResponse } from "@aileron/aileron-data-types/types/API/Response/BaseResponse";
import { ResetPasswordRequest } from "@aileron/aileron-data-types/types/API/Identity/ResetPasswordRequest";
import { ResendConfirmationRequest } from "@aileron/aileron-data-types/types/API/Identity/ResendConfirmationRequest";
import { ConfirmAccountRequest } from "@aileron/aileron-data-types/types/API/Identity/ConfirmAccountRequest";

import { JsonConvert, OperationMode, ValueCheckingMode } from "json2typescript";
import { NewUser } from "@aileron/aileron-data-types/types/API/Identity/NewUser";

let jsonConvert: JsonConvert = new JsonConvert();
jsonConvert.operationMode = OperationMode.ENABLE;
jsonConvert.ignorePrimitiveChecks = false;
jsonConvert.valueCheckingMode = ValueCheckingMode.DISALLOW_NULL;

export default class AuthenticationClient {
  public static async authenticateUser(
    emailAddress: string,
    password: string
  ): Promise<UserAuthenticationResponse> {
    let authenticationResponse: UserAuthenticationResponse;

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    let authenticationRequest: UserAuthenticationRequest =
      new UserAuthenticationRequest();
    authenticationRequest.emailAddress = emailAddress;
    authenticationRequest.password = password;

    let authenticationUrl: string =
      process.env.VUE_APP_API_AUTHENTICATION_BASE_URL + "/authentication/user";
    try {
      let response: AxiosResponse = await axios.post(
        authenticationUrl,
        jsonConvert.serialize(authenticationRequest),
        axiosConfig
      );
      authenticationResponse = jsonConvert.deserializeObject(
        response.data,
        UserAuthenticationResponse
      );
    } catch (error) {
      let errorMessage: string = "Could not authenticate user.";

      if (error instanceof Error) {
        errorMessage.concat(" Error: " + error.message);
      }
      throw new Error(errorMessage);
    }

    return authenticationResponse;
  }

  public static async selfRegisterUser(
    emailAddress: string,
    password: string,
    firstName: string,
    lastName: string
  ): Promise<RegistrationResponse> {
    let registrationResponse: RegistrationResponse;

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    let regRequest: RegistrationRequest = new RegistrationRequest();
    regRequest.user = new NewUser();
    regRequest.user.emailAddress = emailAddress;
    regRequest.user.firstName = firstName;
    regRequest.user.lastName = lastName;
    regRequest.user.password = password;
    console.log("User: " + JSON.stringify(regRequest));

    let registrationUrl: string =
      process.env.VUE_APP_API_AUTHENTICATION_BASE_URL + "/registration/me";
    try {
      let response: AxiosResponse = await axios.post(
        registrationUrl,
        jsonConvert.serialize(regRequest.user),
        axiosConfig
      );
      registrationResponse = jsonConvert.deserializeObject(
        response.data,
        RegistrationResponse
      );
    } catch (error) {
      let errorMessage: string = "Could not self register user.";

      if (error instanceof Error) {
        errorMessage.concat(" Error: " + error.message);
      }
      throw new Error(errorMessage);
    }

    return registrationResponse;
  }

  public static async recoverPassword(emailAddress: string) {
    let recoveryResponse: BaseResponse = new BaseResponse();
    recoveryResponse.message = "";
    recoveryResponse.successful = true;

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    let recoverRequest: ResendConfirmationRequest =
      new ResendConfirmationRequest();
    recoverRequest.emailAddress = emailAddress;

    let recoverUrl: string =
      process.env.VUE_APP_API_AUTHENTICATION_BASE_URL +
      "/account/recover-password";
    try {
      let response: AxiosResponse = await axios.post(
        recoverUrl,
        jsonConvert.serialize(recoverRequest),
        axiosConfig
      );
      recoveryResponse = jsonConvert.deserializeObject(
        response.data,
        BaseResponse
      );
    } catch (error) {
      let errorMessage: string = "Could not self register user.";

      if (error instanceof Error) {
        errorMessage.concat(" Error: " + error.message);
      }
      throw new Error(errorMessage);
    }

    return recoveryResponse;
  }

  public static async resetPassword(
    confirmationCode: string,
    newPassword: string
  ) {
    let resetResponse: BaseResponse = new BaseResponse();
    resetResponse.message = "";
    resetResponse.successful = true;

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    let resetPasswordRequest: ResetPasswordRequest = new ResetPasswordRequest();
    resetPasswordRequest.confirmationCode = confirmationCode;
    resetPasswordRequest.password = newPassword;

    let resetPasswordUrl: string =
      process.env.VUE_APP_API_AUTHENTICATION_BASE_URL +
      "/account/reset-password";
    try {
      let response: AxiosResponse = await axios.post(
        resetPasswordUrl,
        jsonConvert.serialize(resetPasswordRequest),
        axiosConfig
      );
      resetResponse = jsonConvert.deserializeObject(
        response.data,
        BaseResponse
      );
    } catch (error) {
      let errorMessage: string = "Could not self register user.";

      if (error instanceof Error) {
        errorMessage.concat(" Error: " + error.message);
      }
      throw new Error(errorMessage);
    }

    return resetResponse;
  }

  public static async resendConfirmationCode(emailAddress: string) {
    let resendConfirmationResponse: BaseResponse = new BaseResponse();
    resendConfirmationResponse.message = "";
    resendConfirmationResponse.successful = true;

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    let resendRequest: ResendConfirmationRequest =
      new ResendConfirmationRequest();
    resendRequest.emailAddress = emailAddress;

    let resetConfirmationUrl: string =
      process.env.VUE_APP_API_AUTHENTICATION_BASE_URL +
      "/registration/confirmation/resend-code";
    try {
      let response: AxiosResponse = await axios.post(
        resetConfirmationUrl,
        jsonConvert.serialize(resendRequest),
        axiosConfig
      );
      resendConfirmationResponse = jsonConvert.deserializeObject(
        response.data,
        BaseResponse
      );
    } catch (error) {
      let errorMessage: string = "Could not resend confirmation code.";

      if (error instanceof Error) {
        errorMessage.concat(" Error: " + error.message);
      }
      throw new Error(errorMessage);
    }

    return resendConfirmationResponse;
  }

  public static async emailExists(emailAddress: string): Promise<boolean> {
    let emailExistsResponse: BaseResponse = new BaseResponse();
    emailExistsResponse.message = "";
    emailExistsResponse.successful = true;

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    let resendRequest: ResendConfirmationRequest =
      new ResendConfirmationRequest();
    resendRequest.emailAddress = emailAddress;

    let resetConfirmationUrl: string =
      process.env.VUE_APP_API_AUTHENTICATION_BASE_URL +
      "/registration/email-exists";
    try {
      let response: AxiosResponse = await axios.post(
        resetConfirmationUrl,
        jsonConvert.serialize(resendRequest),
        axiosConfig
      );
      emailExistsResponse = jsonConvert.deserializeObject(
        response.data,
        BaseResponse
      );
    } catch (error) {
      let errorMessage: string = "Could not resend confirmation code.";

      if (error instanceof Error) {
        errorMessage.concat(" Error: " + error.message);
      }
      throw new Error(errorMessage);
    }

    return emailExistsResponse.successful;
  }

  public static async confirmAccount(confirmationCode: string) {
    let resendConfirmationResponse: BaseResponse = new BaseResponse();
    resendConfirmationResponse.message = "";
    resendConfirmationResponse.successful = true;

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    let confirmationRequest: ConfirmAccountRequest =
      new ConfirmAccountRequest();
    confirmationRequest.confirmationCode = confirmationCode;

    let confirmationUrl: string =
      process.env.VUE_APP_API_AUTHENTICATION_BASE_URL +
      "/registration/confirmation/validate-code";
    try {
      let response: AxiosResponse = await axios.post(
        confirmationUrl,
        jsonConvert.serialize(confirmationRequest),
        axiosConfig
      );
      resendConfirmationResponse = jsonConvert.deserializeObject(
        response.data,
        BaseResponse
      );
    } catch (error) {
      let errorMessage: string = "Could not resend confirmation code.";

      if (error instanceof Error) {
        errorMessage.concat(" Error: " + error.message);
      }
      throw new Error(errorMessage);
    }

    return resendConfirmationResponse;
  }
}
