
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  name: "AuthenticationLayout",
  components: {

  }
})
export default class AuthenticationLayout extends Vue {
    public constructor() {
        super();
    }

    public get pageTitle(): string {
        return this?.$route?.meta?.title;
    }

    public aileronRedirect() {
        console.log("Clicked aileronRedirect");
    }

    public mounted(): void {
    }
}
