import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import './scss/main.scss';

/* Import layouts here */
import Layout from "@/components/core/layout/Layout.vue";
import AuthenticationLayout from "@/components/core/layout/AuthenticationLayout.vue";

Vue.component('common-layout', Layout);
Vue.component('authentication-layout', AuthenticationLayout);

Vue.config.productionTip = false;
Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add(faUserSecret);
library.add(fas);
library.add(far);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
