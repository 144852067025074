
import Vue from "vue";
import { Component } from 'vue-property-decorator';

@Component( {
    name: "App",
    components: {
    }
})
export default class App extends Vue {
  public constructor() {
    super();
  }

  public get viewLayout(): string {
    return (this?.$route?.meta?.layout || 'common') + '-layout';
      }
}
