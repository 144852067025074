import { Dictionary } from "@aileron/aileron-data-types/types/utilities/Dictionary";

export default class AuthenticationMessages {
    private messages: Dictionary<string>;

    public constructor() {
        this.messages = new Dictionary<string>();
        
        this.messages.Add("", "Login failed");
        this.messages.Add("17005", "Your email address has not been confirmed yet, if you did not receive an email please try to resend using the bottom below and also verify the message didn't go into your spam folder. If the problem persists please contact Aileron Client Support.");
    }

    public getMessage(error): string {
        let message: string = error.description;

        if(this.messages.ContainsKey(error.code)) {
            message = this.messages.Item(error.code);
        }

        return message;
    }
}