
import Vue from "vue";
import {Component} from "vue-property-decorator";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {email, required} from "vee-validate/dist/rules";

import AuthenticationClient from "@/services/AuthenticationClient";
import {UserAuthenticationResponse} from "@aileron/aileron-data-types/types/API/Identity/UserAuthenticationResponse";
import AuthenticationMessages from "@/services/AuthenticationMessages";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("email", {
  ...email,
  message: "Please enter a valid email address",
});

extend("registeredEmail", {
    async validate(value) {
      return await AuthenticationClient.emailExists(value);
    },
    message: "We don’t have an account associated with that email address. Click “Create Account” to sign up!"
})

@Component({
  name: "Login",
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class LoginView extends Vue {
  public loginFormValid: boolean = false;
  public email: string = "";
  public password: string = "";

  public rules: any;

  /* IDP Variables */
  public authenticationType: string;
  public sessionDataKey: string;
  public idpUsername: string;

  public authError: boolean;
  public authErrorMessage: string;

  public messageClient: AuthenticationMessages;
  public showResendConfirmation: boolean = false;
  public signingIn: boolean = false;
  public rememberMe: boolean = false;

  public redirectingToSP: boolean = true;
  public isMounted: boolean = false;

  public constructor() {
    super();
    this.authenticationType = "";
    this.sessionDataKey = "";
    this.idpUsername = "";

    this.authError = false;
    this.authErrorMessage = "";
    this.messageClient = new AuthenticationMessages();
    this.loginFormValid = false;
    this.redirectingToSP = false;
  }

  public get identityServerRedirectUrl(): string {
    let redirectUrl: string = "";

    if (this.authenticationType === "samlsso") {
      redirectUrl = this.idpBaseUrl + "/samlsso";
    } else if (this.authenticationType === "oauth2") {
      redirectUrl = this.idpBaseUrl + "/oauth2/authorize";
    } else {
      redirectUrl = this.idpBaseUrl + "/commonauth";
    }

    return redirectUrl;
  }

  public get idpBaseUrl(): string {
    return process.env.VUE_APP_IDM_HOSTNAME;
  }

  public async signIn(): Promise<void> {

    if(!this.loginFormValid) {
      this.authError = true;
      this.authErrorMessage = "Please enter required fields";
    }
    else {
      try{
        this.signingIn = true;
        let authResponse: UserAuthenticationResponse = await AuthenticationClient.authenticateUser(this.email, this.password);
        this.authError = !authResponse.successful;

        if(this.authError) {
          this.authErrorMessage = this.messageClient.getMessage(authResponse.error);
          if(authResponse.error.code === "17005") {
            this.showResendConfirmation = true;
          }
          else {
            /* Let's see if their email is found, if not we will want to show registration button */
            let test: boolean = await AuthenticationClient.emailExists(this.email);
          }
          this.signingIn = false;
        }
        else {
          this.idpUsername = authResponse.username;

          //@ts-ignore
          this.$refs.idpUsername.value = authResponse.username;

          //@ts-ignore
          this.$refs.signupForm.action = this.identityServerRedirectUrl;

          //@ts-ignore
          this.$refs.idpRememberMe.value = this.rememberMe;

          this.redirectingToSP = true;

          //@ts-ignore
          this.$refs.signupForm.submit();
        }
      }
      catch(error) {
        this.authErrorMessage = "Failed to sign in, please wait a couple minutes and try again. If the problem persists please call Aileron Client Support";
        this.signingIn = false;
      }
    }
  }

  public selfRegistration(): void {
    this.$router.push({ name: "SelfRegistration" });
  }

  public recoverAccount(): void {
    this.$router.push({name: "RecoverAccount"});
  }

  public emailChanged() {
      this.authError = false;
  }

  public passwordChanged() {
    this.authError = false;
  }

  public mounted(): void {
    this.sessionDataKey = this.$route.query.sessionDataKey as string;
    this.authenticationType = this.$route.query.type as string;
    this.isMounted = true;
  }
}
