
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  name: "Layout",
  components: {}
})
export default class Layout extends Vue {
  public constructor() {
    super();
  }

  public aileronRedirect() {
    console.log("Clicked aileronRedirect");
  }
}
